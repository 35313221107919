<template>
  <Popup ref="popupRef" width="610px" title="新建顾问分配" confirm-text="确认" :confirmLoading="confirmLoading" @confirm="confirm" @close="initForm">
    <el-form label-position="top">
      <el-form-item label="商户注册账号" required>
        <el-input placeholder="请输入注册账号" v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="招聘类目">
        <el-input placeholder="请输入招聘类目" v-model="form.category"></el-input>
      </el-form-item>
      <el-form-item label="客户来源">
        <el-input placeholder="请输入客户来源" v-model="form.source"></el-input>
      </el-form-item>
      <el-form-item label="联系人">
        <el-input placeholder="请输入联系人" v-model="form.contactName"></el-input>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input placeholder="请输入联系方式" v-model="form.contactInfo"></el-input>
      </el-form-item>
      <el-form-item label="签约状态" required>
        <el-radio-group v-model="form.status">
          <el-radio :label='item.type' v-for="(item,index) in statusTypes" :key="index">{{item.text}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input placeholder="请输入备注" type="textarea" v-model="form.memo"></el-input>
      </el-form-item>
    </el-form>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { consultantConnectApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      statusTypes: [
        {text: '未合作', type:0},
        {text: '未签约', type:1},
        {text: '已签约', type:2},
      ],
      form: {},
      confirmLoading: false
    }
  },
  created() {
    this.initForm()
  },
  methods: {
    open(option) {
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    initForm() {
      this.form = {
        status: 0,
        category: '',
        mobile: '',
        contactInfo: '',
        contactName: '',
        memo: '',
        source: ''
      }
    },
    confirm() {
      if(!this.form.mobile.trim()) return this.$tips({message: '请输入注册账号', type:'warning'})
      const formData = {
        status: this.form.status,
        category: this.form.category,
        mobile: this.form.mobile,
        contactInfo: this.form.contactInfo,
        contactName: this.form.contactName,
        memo: this.form.memo,
        source: this.form.source
      }
      console.log('formdata', formData)
      this.confirmLoading = true
      consultantConnectApi(formData).then(res => {
        console.log('新建顾问分配', res)
        this.confirmLoading = false
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.confirmLoading = false
        this.$tips({message: err, type:'warning'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>